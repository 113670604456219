import { NIcon } from "naive-ui";
import { BookOutline as BookIcon, PersonOutline as PersonIcon, ChatbubbleEllipsesOutline, GlobeOutline, InfiniteOutline, SchoolOutline, HeartOutline, HardwareChipOutline, PulseOutline, BluetoothOutline, FolderOutline, ShareSocialOutline, GameControllerOutline, AppsOutline, AnalyticsOutline, HappyOutline, GitMergeOutline, HomeOutline } from "@vicons/ionicons5";
function renderIcon(icon) {
  return () => h(NIcon, null, {
    default: () => h(icon)
  });
}
const changeColor = value => {
  let str = "#";
  let temp = 29;
  if (value >= 0 && value <= 50) {
    temp = 29 + 3 * (50 - value);
    str = str + "1d" + temp.toString(16) + "c8";
    themeOverrides.common.primaryColor = str;
    themeOverrides.Slider.railColorHover = str;
    themeOverrides.Slider.fillColorHover = str;
    colorData.color = str;
  } else {
    temp = 29 + 3 * (value - 50);
    str = str + temp.toString(16) + "1d" + "c8";
    themeOverrides.common.primaryColor = str;
    themeOverrides.Slider.railColorHover = str;
    themeOverrides.Slider.fillColorHover = str;
    colorData.color = str;
  }
};
const menuOptions = [{
  label: () => h(RouterLink, {
    to: {
      name: "about-me"
    }
  }, {
    default: () => "About Me"
  }),
  key: "about-me",
  icon: renderIcon(PersonIcon)
}, {
  label: "Projects",
  key: "my-projects",
  icon: renderIcon(BookIcon),
  children: [{
    label: "Learning Project",
    key: "learning-resources",
    icon: renderIcon(SchoolOutline),
    children: [{
      label: () => h(RouterLink, {
        to: {
          name: "neurotechnology-and-programming"
        }
      }, {
        default: () => "Neurotechnology"
      }),
      key: "neurotechnology-and-programming",
      icon: renderIcon(FolderOutline)
    }, {
      label: () => h(RouterLink, {
        to: {
          name: "ai-system"
        }
      }, {
        default: () => "AI System"
      }),
      key: "ai-system",
      icon: renderIcon(AnalyticsOutline)
    }, {
      label: () => h(RouterLink, {
        to: {
          name: "leanring-neural-network"
        }
      }, {
        default: () => "Leanring Neural Network"
      }),
      key: "leanring-neuralNetwork",
      icon: renderIcon(ShareSocialOutline)
    }, {
      label: () => h(RouterLink, {
        to: {
          name: "graph-theory"
        }
      }, {
        default: () => "Graph Theory"
      }),
      key: "graph-theory",
      icon: renderIcon(GitMergeOutline)
    }]
  }, {
    label: "Web Project",
    key: "web-project",
    icon: renderIcon(GlobeOutline),
    children: [{
      label: () => h(RouterLink, {
        to: {
          name: "chatex"
        }
      }, {
        default: () => "Chatex"
      }),
      key: "chatex",
      icon: renderIcon(InfiniteOutline)
    }, {
      label: () => h(RouterLink, {
        to: {
          name: "adopt-animal"
        }
      }, {
        default: () => "AdoptAnimal"
      }),
      key: "adopt-animal",
      icon: renderIcon(HeartOutline)
    }, {
      label: () => h(RouterLink, {
        to: {
          name: "home-page"
        }
      }, {
        default: () => "HomePage"
      }),
      key: "home-page",
      icon: renderIcon(HomeOutline)
    }]
  }, {
    label: "Neurotechnology Project",
    key: "neurotechnology-project",
    icon: renderIcon(PulseOutline),
    children: [{
      label: () => h(RouterLink, {
        to: {
          name: "eeg-emotion-recognition"
        }
      }, {
        default: () => "Emotion Recognition"
      }),
      key: "eeg-emotion-recognition",
      icon: renderIcon(HappyOutline)
    }]
  }, {
    label: "Embedded Project",
    key: "embedded-project",
    icon: renderIcon(HardwareChipOutline),
    children: [{
      label: () => h(RouterLink, {
        to: {
          name: "bluetooth-car"
        }
      }, {
        default: () => "Bluetooth Car"
      }),
      key: "bluetooth-car",
      icon: renderIcon(BluetoothOutline)
    }]
  }, {
    label: "Application Project",
    key: "Application-project",
    icon: renderIcon(AppsOutline),
    children: [{
      label: () => h(RouterLink, {
        to: {
          name: "ai-gobang"
        }
      }, {
        default: () => "AI GoBang"
      }),
      key: "ai-gobang",
      icon: renderIcon(GameControllerOutline)
    }]
  }]
}, {
  label: () => h(RouterLink, {
    to: {
      name: "contact-me"
    }
  }, {
    default: () => "Contact me"
  }),
  key: "contact-me",
  disabled: false,
  icon: renderIcon(ChatbubbleEllipsesOutline)
}];
const colorData = reactive({
  color: "#1d1dc8"
});
const themeColor = "#1d1dc8";
const themeOverrides = reactive({
  common: {
    primaryColor: themeColor
  },
  Slider: {
    railColor: "#FFFFFF",
    fillColor: "#FFFFFF",
    railColorHover: themeColor,
    fillColorHover: themeColor
  }
});
const value = ref(50);
let flag = true;
const disabled = ref(true);
const addNum = () => {
  if (value.value === 0) {
    flag = false;
  }
  if (value.value === 100) {
    flag = true;
  }
  if (flag) {
    value.value = value.value - 1;
  }
  if (!flag) {
    value.value = value.value + 1;
  }
};
let timer;
export default defineComponent({
  setup() {
    watch(value, (value, oldValue) => {
      if (value !== oldValue) {
        changeColor(value);
      }
    });
    watch(disabled, (value, oldValue) => {
      if (value !== oldValue) {
        if (value === true) {
          timer = window.setInterval(() => {
            setTimeout(addNum, 0);
          }, 70);
        } else {
          setTimeout(() => {
            clearInterval(timer);
          }, 0);
        }
      }
    });
    timer = window.setInterval(() => {
      setTimeout(addNum, 0);
    }, 70);
    return {
      inverted: ref(false),
      menuOptions,
      themeOverrides,
      value,
      InfiniteOutline,
      defaultExpandedKeys: ["my-projects"],
      colorData,
      disabled
    };
  }
});